import { Form, Button, Modal, Toast, ToastContainer,InputGroup, Row, Col,Table } from "react-bootstrap";
import UserContext from "../../../Context/UserContext";
import ClientContext from "../../../Context/ClientContext"
import ParametroContext from "../../../Context/ParametrosContext";
import { MultiSelect } from "react-multi-select-component";
import { useContext, useState, useEffect, useReducer} from "react"
import { useNavigate } from "react-router-dom";
import { BarLoader } from "react-spinners";
import { BiSearchAlt, BiEditAlt } from 'react-icons/bi'
import { useForm } from "react-hook-form";
import axios from "axios"
import placeimage from "../../../Images/placeholder.jpg"
import './Inicio.css'
import { EChart } from '@kbox-labs/react-echarts'

export default function InicioContainer() {    
    let Zeries = []
    const API_URL = 'https://api.medicionanalytic.com/api'
    const Image_URL = 'https://api.medicionanalytic.com/medicionapi/storage/app/public'
    const newLocation = useNavigate();
    const {paramData} = useContext(ParametroContext);
    const [loading, setLoad] = useState(false)        
    const [_2, forceUpdate2] = useReducer(x => x + 1, 0);

    const [clientes, setClientes] = useState([])
    const [FC, setFC] = useState([])
    const [changeView, setCV] = useState(false)
    const [cities, setCities] = useState([])

    const { logData, setLog } = useContext(ClientContext);
    const { userData } = useContext(UserContext);        
    
    const [mainValue, setMain] = useState(0)
    const [MG, setMG] = useState([])
    const [PG, setPG] = useState([])
    const [FS, setFS] = useState([])
    const [toast, setToast] = useState({ show: false, variant: "", message: "" });
    
    const [oSecciones, setS] = useState([])
    const [Sucursales, setSuc] = useState([]) //filtro de sucursales.
    const [enc, setE] = useState({})
    const [eSec, setESEC] = useState() // seccion seleccionada para encuestadores.
    const [eSuc, setESUC] = useState() // sucursal selecionada para encuestadores
    
    const [periodos, setPeriodos] = useState([])
    const [encuestas, setEnc] = useState([])
    const [puntos, setPuntos] = useState([])
    const [secciones, setSecciones] = useState([])
    const [respuestas, setRespuestas]= useState([])    

    const [FP, setFP] = useState([])
    const [PS, setPS] = useState([])
    const [dataFiltered, setDF] = useState([])

    const [sPeriods, setSP] = useState([])
    const [sPuntos, setSPtos] = useState([])
    const [sSecciones, setSS] = useState([])

    const [segmentos, setSegmentos] = useState([])
    const [AttrMens, setAtrr] = useState([])    
    
    const [reportes, setRep] = useState([])
    const [encPer, setEncPer] = useState()
    const [preguntas, setPreguntas] = useState([])

    const {
        register,
        handleSubmit,
        getValues,
        reset,
        formState: { errors },
    } = useForm({ mode: "onBlur" });

    const [show, setShow] = useState(false)
    const [showEdit, setEdit] = useState(false)

    const handleShow = () => {
        setShow(true);}
    const handleEditClose = () => {
        setEdit(false)        
        reset({
            id:null,
            nombre_cliente: null,
            servicio_audit: null,
            servicio_mystery: null,
            servicio_encuesta: null,
        });
    }
    const handleClose = () => {
        setShow(false);
        reset({
            id:null,
            nombre_cliente: null,
            servicio_audit: null,
            servicio_encuesta: null,
            servicio_mystery: null,
        });
    };

    useEffect(() => {
        if(userData.permiso === "encuestador") {
            axios.get(API_URL+'/reporte/encuestador', {params: { encuestador_id:userData.id }}).then(resp => {
                setRep(resp.data)
                })
            }
        if(userData.permiso === "labor") {setLoad(true)}
        if(userData.permiso === 'admin') {            
            axios.get(API_URL + '/cliente', {params : {admin_id : userData.id, subscription:userData.subscription}}).then(
                function (response) {
                    if(userData.subscription === "super") {
                        if(response.data.length === 1) { handleClientChange(response.data[0],null);window.localStorage.setItem("client", JSON.stringify(response.data[0]));window.localStorage.setItem("user", JSON.stringify(userData)) }
                        setClientes(response.data); setFC(response.data); setLoad(true)
                    }
                    else {
                        axios.get(API_URL+'/cliente/all').then(res => {
                            let c = userData.clients ? res.data.filter(el=>{ return userData.clients.split(",").indexOf(el.id.toString()) > -1 }) : []; 
                            if(response.data.length === 1) { handleClientChange(response.data[0],null); window.localStorage.setItem("client", JSON.stringify(response.data[0]));window.localStorage.setItem("user", JSON.stringify(userData)); }                 
                            const mergedArray = response.data.concat(c.filter(item2 => !response.data.some(item1 => item1.id === item2.id)));
                            setClientes(mergedArray);setFC(mergedArray);setLoad(true);
                        })}
                })}
        if(userData.permiso !== 'admin' && userData.cliente_id > 0) {getAll(userData.cliente_id);}
    }, [userData, _2])
    
    useEffect(() => {
        if((userData.permiso !== 'admin' && userData.cliente_id > 0 && periodos.length > 0) || changeView)
        {   
            let dataper = periodos.filter(pnt => {if(respuestas.filter(d => d.periodo_id === pnt.id).length > 0) {return pnt}})                   
            PromedioInit(dataper[dataper.length > 0 ? dataper.length-1 : 0],encuestas[encuestas.length > 0 ? encuestas.length-1 : 0],Sucursales,secciones,respuestas)
        }
    },[periodos])
    //Graph config    
     const config = {
        rotate: 0,
        align: 'center',
        verticalAlign: 'middle',
        position: 'insideBottom',
        distance: 15,        
      };
      const labelOption = {
        show: true,
        position: 'inside',
        distance: config.distance,
        align: config.align,
        verticalAlign: config.verticalAlign,
        rotate: config.rotate,
        formatter: '{c}',
        fontSize: 16,        
      };      
      const labelOption2 = {
        show: true,
        position: 'inside',
        distance: config.distance,
        align: config.align,
        verticalAlign: config.verticalAlign,
        rotate: config.rotate,
        formatter: '{c}',
        fontSize: 12,        
      };      

    const Clean = (client) => {
        setSPtos([])
        setSP([])
        setSS([])
        setE({})
        getAll(client)
    }  
    const getAll = (client) => {             
        let sum = [];
        let preguntas = [];
        let n = [];
        axios.get(API_URL + '/encuesta/all', { params: { client_id:client} }).then((response) => {                
                let temp;
                if(typeof response.data === 'object') {temp = Object.keys(response.data).map((key) => response.data[key]);}
                else { temp = response.data }
                if(userData.permiso === "admin") { n = temp.filter(encuesta => encuesta.punto_encuesta !== null);}
                else {
                    let vat = temp.filter(el=>{ return userData.encuestas.split(",").indexOf(el.id.toString()) > -1 }); 
                    n = vat.filter(encuesta => encuesta.punto_encuesta !== null);}                
                setEnc(n)       
            })                    
        setTimeout(()=>{  
            axios.get(API_URL + '/pregunta/all', {params: {client_id: client}}).then((res)=>{setPreguntas(res.data); preguntas = res.data});
            axios.get(API_URL+'/segmento', { params: { client_id:client, tipo:"pregunta" } }).then((response) => {
            let temp = response.data
            temp.forEach(el => {el.label = el.nombre_segmento; el.value = el.id;})
            setSegmentos(temp)
        })                  
        axios.get(API_URL + '/periodo', { params: { client_id:client } }).then( (response) => {
            response.data.forEach(p => { p.label = p.nombre_periodo; p.value = p.id;})
            response.data.sort(function(a,b){ return new Date(a.periodo_inicio) - new Date(b.periodo_inicio);});
            setPeriodos(response.data)}); 
        axios.get(API_URL + '/ciudad',{ params:{ client_id:client }}).then((response) => { response.data.forEach(p => { p.label = p.nombre_ciudad; p.value = p.id; }); setCities(response.data); });        
        axios.get(API_URL + '/sucursal', { params: { cliente_id:client } }).then((res) => { 
            res.data.forEach(p => { p.label = p.nombre_sucursal; p.value = p.id; }); 
            if(logData.id === 49 && userData.permiso !== "encuestador") {setPuntos(res.data)} 
            else {setSuc(res.data)} })            
        axios.get(API_URL + '/seccion/report', { params: { client_id:client } }).then((response) => {
                  response.data.forEach(p => {
                      p.label = p.nombre_seccion;
                      p.value = p.id;
                  })                  
                  setSecciones(response.data)})                                               
        axios.get(API_URL+'/respuesta', { params:enc.id }).then(res=>{                   
            let temp = res.data             
            let temp2 = [] 
            axios.get(API_URL + '/video', { params: { client_id: client} }).then((response) => { 
                let videos = response.data.filter(v => {return v.estado !== "inactivo"})                     
                if(userData.permiso === "admin" || userData.permiso === "gerente") {temp2 = temp}
                else {
                    let tetemp = temp.filter(el=> { return userData.encuestas.split(",").some(s => {return Number(el.encuesta_id) === Number(s) }) })                   
                    temp2 = tetemp.filter(p => { return videos.some(v => { return (p.video_id === v.id || p.video_id === null) }) }) }                
                    temp2.forEach((r)=>{                         
                        if(r.tipo_pregunta === 'escala-tri') {
                            if(r.respuesta === 'malo') { r.Value=0;r.Segmento= preguntas.find(p => p.id === r.pregunta_id) ? preguntas.find(p => p.id === r.pregunta_id).segmento : "" ; sum.push(r)}
                            if(r.respuesta === 'regular') { r.Value =50; r.Segmento= preguntas.find(p => p.id === r.pregunta_id) ? preguntas.find(p => p.id === r.pregunta_id).segmento : "" ;sum.push(r)}
                            if(r.respuesta === 'excelente') { r.Value = 100; r.Segmento = preguntas.find(p => p.id === r.pregunta_id) ? preguntas.find(p => p.id === r.pregunta_id).segmento : "" ;sum.push(r)}
                        }
                        if(r.tipo_pregunta === "escala") { r.Value = ((r.respuesta*100)/r.valor_max); r.Segmento = preguntas.find(p => p.id === r.pregunta_id) ? preguntas.find(p => p.id === r.pregunta_id).segmento : "" ;sum.push(r)}
                        if(r.tipo_pregunta === "thumbs") {
                            if(r.respuesta === "si") {r.Value = 100; r.Segmento = preguntas.find(p => p.id === r.pregunta_id) ? preguntas.find(p => p.id === r.pregunta_id).segmento : ""; sum.push(r)}
                            else if(r.respuesta === "no"){ r.Value = 0; r.Segmento = preguntas.find(p => p.id === r.pregunta_id) ? preguntas.find(p => p.id === r.pregunta_id).segmento : "";sum.push(r)}
                        }
                        if(r.tipo_pregunta === "stars"){ r.Value = ((r.respuesta*100)/5); r.Segmento = preguntas.find(p => p.id === r.pregunta_id) ? preguntas.find(p => p.id === r.pregunta_id).segmento : ""; sum.push(r)}
                        if(r.tipo_pregunta === "nps" || (r.tipo_pregunta === "npsna" && r.respuesta !== "na") ){ r.Value = ((r.respuesta*100)/10); r.Segmento = preguntas.find(p => p.id === r.pregunta_id) ? preguntas.find(p => p.id === r.pregunta_id).segmento : ""; sum.push(r)}
                        if(r.tipo_pregunta === "smiles"){ r.Value = (r.respuesta*100)/5; r.Segmento = preguntas.find(p => p.id === r.pregunta_id) ? preguntas.find(p => p.id === r.pregunta_id).segmento : ""; sum.push(r)}
                        if(r.tipo_pregunta === "audit") {
                            if(r.respuesta === "si") { r.Value = 100; r.Segmento = preguntas.find(p => p.id === r.pregunta_id) ? preguntas.find(p => p.id === r.pregunta_id).segmento : ""; sum.push(r)}
                            else if(r.respuesta === "no"){ r.Value = 0; r.Segmento = preguntas.find(p => p.id === r.pregunta_id) ? preguntas.find(p => p.id === r.pregunta_id).segmento : ""; sum.push(r)}}
                        })                      
                    setRespuestas(sum)                     
                    })            
                })                  
    },[500])
}
    const setHeaders = () => {
        let token = window.localStorage.getItem("sessionKey");
        const headers = {
            Authorization: `Bearer ${token}`,
            Accept: "application/json",
            ContentType: "multipart/form-data; charset=utf-8;",
        };
        return headers;
        }
    const CallModal = (datax, e) => {
        e.preventDefault();
        let formData = new FormData();        
        if(datax.file.length > 0) {formData.append("logo", datax.file[0])}
        datax.servicio_basic ? formData.append("servicio_basic","activo") :formData.append("servicio_basic","inactivo")
        datax.servicio_encuesta ? formData.append("servicio_encuesta","activo") :formData.append("servicio_encuesta","inactivo")
        datax.servicio_shop ? formData.append("servicio_shop","activo") : formData.append("servicio_shop","inactivo")
        datax.servicio_audit ? formData.append("servicio_audit","activo") : formData.append("servicio_audit","inactivo")
        datax.servicio_mystery ? formData.append("servicio_mystery","activo") : formData.append("servicio_mystery","inactivo")
        datax.servicio_climaLaboral ? formData.append("servicio_climaLaboral","activo") : formData.append("servicio_climaLaboral","inactivo")
        datax.servicio_desempeno ? formData.append("servicio_desempeno","activo") : formData.append("servicio_desempeno","inactivo")
        formData.append("nombre_cliente", datax.nombre_cliente);
        formData.append("admin_id", userData.id);
        axios.post(API_URL + '/cliente', formData).then(
            function (response) {
                Toaster("success", response.data.message)
                handleClose()
                forceUpdate2()
            }
        ) 
    }
    const handleDelete = () => {
        let curr = getValues()
        let headers = setHeaders()        
        axios.delete(API_URL+`/cliente/${curr.id}`, {headers}). then(response => {
            Toaster("success", response.data.message)
            handleEditClose()
            forceUpdate2()
        })
    }
    const EditModal = (datax, e) => {        
        e.preventDefault();
        let headers = setHeaders();
        let formData = new FormData();
        if(datax.file.length > 0) {formData.append("logo", datax.file[0])}
        datax.servicio_basic ? formData.append("servicio_basic","activo") :formData.append("servicio_basic","inactivo")
        datax.servicio_encuesta ? formData.append("servicio_encuesta","activo") :formData.append("servicio_encuesta","inactivo")
        datax.servicio_shop ? formData.append("servicio_shop","activo") : formData.append("servicio_shop","inactivo")
        datax.servicio_audit ? formData.append("servicio_audit","activo") : formData.append("servicio_audit","inactivo")
        datax.servicio_mystery ? formData.append("servicio_mystery","activo") : formData.append("servicio_mystery","inactivo")
        datax.servicio_climaLaboral ? formData.append("servicio_climaLaboral","activo") : formData.append("servicio_climaLaboral","inactivo")
        datax.servicio_desempeno ? formData.append("servicio_desempeno","activo") : formData.append("servicio_desempeno","inactivo")
        formData.append("nombre_cliente", datax.nombre_cliente);
        formData.append("admin_id", userData.id);
        formData.append("id",datax.id)
        axios.post(API_URL + '/cliente/update', formData,{ headers }).then(
            function (response) {
                Toaster("success", response.data.message)
                handleEditClose()
                forceUpdate2()
            }
        ) 
    }
    const handleClientChange = (c, edit) => {
        if (logData === undefined || logData.id !== c.id ) {setLog(c)}
        else {setLog({nombre_cliente: "",id: 0,})}
        if(edit === true) 
        { 
            reset({
                id: c.id,
                nombre_cliente: c.nombre_cliente,
                servicio_basic : c.servicio_basic === "activo" ? true:false,
                servicio_encuesta: c.servicio_encuesta === "activo" ? true:false,
                servicio_audit: c.servicio_audit === "activo" ? true:false,
                servicio_mystery : c.servicio_mystery === "activo" ? true:false,
                servicio_shop : c.servicio_shop === "activo" ? true:false,
                servicio_climaLaboral : c.servicio_climaLaboral === "activo" ? true:false,
                servicio_desempeno : c.servicio_desempeno === "activo" ? true:false,
            });
            setEdit(true)
        }

        setTimeout(()=>{
        if(edit === false) {
            console.log(showEdit) //choca con el concepto de editar, se puede poner de una manera que un child no afecte un on click del parent?
            newLocation('/app/administracion/nueva-encuesta')
            }
        },1000)
    }
    function searchItems(e) {
        setFC(clientes.filter( item => {return Object.values(item).join('').toLowerCase().includes(e.toLowerCase())} ))
    }
    const SetFilters = (data,e) => {
        e.preventDefault();
        let tab = respuestas              
        if(!enc) { Toaster("danger", "seleccione una encuesta a medir"); return; }                
        setFP(sPuntos);
        let temp = []
        let mainGraph = []
        let porpunto = []        
        let Sucursals = []
        let resultPtos = []        
        let sns = []
        let result = 0
        let AM = []
        let DF = []
        
        temp = tab.filter(el => {
            return sPuntos.some(p => {
                return sSecciones.some(s => {
                   return sPeriods.some(f => {                    
                    return Number(p.id) === Number(el.punto_id) && 
                    Number(s.id) === Number(el.seccion_id) && 
                    Number(f.id) === Number(el.periodo_id) && 
                    Number(el.encuesta_id) === Number(enc.id) 
                }) }) }) })                                           
        setPS(sPeriods.filter(pnt => {if(temp.filter(d => d.periodo_id === pnt.id).length > 0) {return pnt}}));        
       sPeriods.filter(pnt => {if(temp.filter(d => d.periodo_id === pnt.id).length > 0) {return pnt}}) //separar data por periodos.
       .forEach(p => {            
            let PeriodValues = []
            let pResult = 0;                        
            temp.filter(t => t.periodo_id === p.id)
            .forEach(p => {                
                let bak = porpunto.findIndex(pnt => pnt.punto_id === p.punto_id)
                if(bak === -1) {let kelp = temp.filter(q => q.punto_id === p.punto_id);porpunto.push(kelp);}
            })                     
            porpunto.forEach(el => {                
                 let acumptos = []                
                 if(el.filter(l=>l.periodo_id === p.id).length > 0) {                                         
                    sSecciones.forEach(s => {                         
                        let line = el.filter(t => t.periodo_id === p.id && t.seccion_id === s.id)                        
                        acumptos.push({ Value:line.reduce(function (acc, obj) { return acc + Number(obj.Value); }, 0)/line.length, S:s.id, })
                    })                                        
                    let rpts = {
                    punto_id: el[0].punto_id,   
                    periodo_id : el.filter(t => t.periodo_id === p.id)[0].periodo_id,                 
                    Value :acumptos.filter(p => {return !isNaN(p.Value)}).reduce(function (acc, obj) { return acc + Number(obj.Value); }, 0)/acumptos.filter(p => {return !isNaN(p.Value)}).length
                }
                sns.push(acumptos.filter(p => {return !isNaN(p.Value)}))
                resultPtos.push(rpts)//resultados de cada punto.                                
                PeriodValues.push(rpts)
                }
             });                          
            sPuntos.forEach(pnt => {                
                let V = resultPtos.filter(r => r.punto_id === pnt.id && r.periodo_id === p.id)                
                    let F = {}
                    if(V.length > 0) {
                    F.y = (V.reduce(function (acc, obj) { return acc + Number(obj.Value); }, 0)/V.length).toFixed(0)
                    F.Periodo_id = p.id
                    F.Punto_id = pnt.id
                    }
                    else {
                        F.y = 'NaN'                        
                        F.Periodo_id = p.id
                        F.Punto_id = pnt.id
                    }
                DF.push(F)        //cambiar sucursals.
            })   //tabla de puntos.                                                            
            segmentos.filter(s => s.encuesta_id === enc.id)
            .forEach(s => {        
                let tot = temp.filter(x => (Number(x.Segmento) === Number(s.id) && x.periodo_id === p.id && x.tipo_pregunta !== "texto"))
                let xS = (tot.reduce(function (acc, obj) { return acc + obj.Value; }, 0)/tot.length)//.filter(t => (t.Value >= 80)).length then xs*100 / length
                let Val = (xS).toFixed(2)                                                
                AM.push({y:Val, Segmento:s.nombre_segmento, segmento_id:s.id, periodo_id: p.id, x:p.nombre_corto})
              }) 

            let P = {}
            P.x = p.nombre_periodo
            pResult = (PeriodValues.filter(t => t.periodo_id === p.id).reduce(function (acc, obj) { return acc + obj.Value; }, 0)/PeriodValues.length).toFixed(0)            
            if(Number(pResult) > 0){ P.y = Number(pResult) }
            else {P.y = 0}                      
            mainGraph.push(P) // grafico de tendencia de la ENCUESTA
        })                         
        //sumatoria de todos los puntos                
        result = (mainGraph.reduce(function (acc, obj) {return acc + obj.y; }, 0)/mainGraph.length).toFixed(0)
        //grafico de puntos
        sPuntos.forEach(p => {
            let V = resultPtos.filter(r => r.punto_id === p.id)
            let F = {}
            if(V.length > 0) {
                F.y = (V.reduce(function (acc, obj) { return acc + obj.Value; }, 0)/V.length).toFixed(0)
                F.x = p.nombre_sucursal
            }
            else {
                F.y = 'NaN';
                F.x = p.nombre_sucursal;
            }
            Sucursals.push(F)        
        })        
        sSecciones.forEach(sec => {
            let acuma = []            
            let auxtot = []            
            let tot = 0                            
            sns.forEach(r => {                        
                let pep = r.filter(s => {return s.S === sec.id})                        
                if(pep.length > 0) {                            
                    tot = Number(tot)+Number(pep[0].Value.toFixed(0))
                    auxtot.push(pep[0])  
                  }
                })                                                   
            if(!isNaN(tot)) { acuma.push((tot/auxtot.length).toFixed(0))}                
            sec.Result = (acuma.reduce(function (acc, obj) {return acc + Number(obj); }, 0)/acuma.length).toFixed(0)
        })                      
        setDF(DF)        
        setMG(mainGraph)        
        setPG(Sucursals)        
        setFS(sSecciones)           
        setAtrr(AM)  
        setMain(result)                
    }
    const PromedioInit = (per, enc, puntos, secciones, sum) => {              
        let tabulada = sum ///data "cruda"
        let temp = []
        let helper = [] //ayudante de secciones
        let Sucursals = []        
        let porpunto = []
        let resultPtos = []
        let mainGraph = [] //grafico de periodo
        let sns = []

        let AM = []
        let DF = []
        let result = 0 //velocimetro principal

        if(userData.permiso !== "admin") { puntos = puntos.filter(el=>{ return userData.puntos.split(",").indexOf(el.id.toString()) > -1 }) }
        if(enc !== undefined && per !== undefined)
        {         
            helper = secciones.filter(s => s.encuesta_id === enc.id)
            temp = tabulada.filter((r) => (r.periodo_id === per.id && r.encuesta_id === enc.id))
            temp.forEach(p => {
            let bak = porpunto.findIndex(pnt => pnt.punto_id === p.punto_id)
            if(bak === -1) {
                    let kelp = temp.filter(q => q.punto_id === p.punto_id)
                    porpunto.push(kelp)}}
                    )                                
            // grafico de Secciones seleccionadas de la encuesta.
            porpunto.forEach(el => {
                let acumptos = []
                helper.forEach(s => {
                    let line = el.filter(r => r.seccion_id === s.id)
                    s.Result = line.reduce(function (acc, obj) { return acc + obj.Value; }, 0)/line.length
                    acumptos.push(s.Result)
                })                 
                let rpts = {
                    punto_id: el[0].punto_id,
                    Value :(acumptos.reduce(function (acc, obj) { return acc + obj; }, 0)/acumptos.length).toFixed(0)
                }
                sns.push(acumptos)
                resultPtos.push(rpts)//resultados de cada punto.
            })     
            //data de grafico de segmentos            
            segmentos.filter(s => s.encuesta_id === enc.id).forEach(s => {        
                let tot = temp.filter(x => (Number(x.Segmento) === Number(s.id) && x.periodo_id === per.id && x.tipo_pregunta !== "texto"))
                let xS = tot.filter(t => (t.Value >= 90)).length                     
                let Val = (xS*100/tot.length).toFixed(0)                
                AM.push({y:Val, Segmento:s.nombre_segmento, segmento_id:s.id, periodo_id: per.id, x:per.nombre_corto})
            })                               
            //data del velocimetro principal.
            let filteredResults
            if(userData.permiso === "admin"){filteredResults = resultPtos}
            else {filteredResults = resultPtos.filter(el=>{ return userData.puntos.split(",").indexOf(el.punto_id.toString()) > -1 })}            
            result = (filteredResults.reduce(function (acc, obj) {return acc + Number(obj.Value); }, 0)/filteredResults.length).toFixed(0)                    
            puntos.forEach(p => {
                let V = resultPtos.filter(r => r.punto_id === p.id)
                let F = {}
                if(V.length > 0) {
                    F.y = (V.reduce(function (acc, obj) { return acc + Number(obj.Value); }, 0)/V.length).toFixed(0);
                    F.x = p.nombre_sucursal;
                }
                else {F.y = 0;F.x = p.nombre_sucursal}
                Sucursals.push(F)        
            }) //grafico todas las sucursale       
            puntos.forEach(pnt => {            
                let V = resultPtos.filter(r => r.punto_id === pnt.id)
                let F = {}                
                if(V.length > 0) {
                    F.y = (V.reduce(function (acc, obj) { return acc + Number(obj.Value); }, 0)/V.length).toFixed(2);
                    F.Periodo_id = per.id;
                    F.Punto_id = pnt.id;
                }
                else { F.y = 0;F.Punto_id = pnt.id }
                DF.push(F)        //cambiar sucursals.
            }) 
            for (let index = 0; index < helper.length; index++) {
                let acuma = 0
                sns.forEach(s => { acuma += s[index] })
                helper[index].Result = (acuma/sns.length).toFixed(2)
            } //grafico de secciones de la encuesta ( sumatoria de secciones por puntos.)
            
            // grafico de tendencia de la ENCUESTA
            let P = {}
            P.x = per.nombre_periodo
            P.y = result
            mainGraph.push(P) 

            setFP(puntos);        
            setPS([per]);            
            //setE(enc);             
            setDF(DF)                                    

            setPG(Sucursals) //sucursales
            setFS(helper) //secciones
            setMG(mainGraph) //periodo barra
            setAtrr(AM)          
            setMain(result) // resultado general (velocimetro)
            setTimeout(()=>{                
                setLoad(true)                                                   
            },500)
    }           
    else { setLoad(true); if(userData.permiso !== "encuestador") { Toaster("warning", "no se encontro data ó se excedió el tiempo de espera.") } }   
}
    const handleEncuesta = (e) => {
        e.preventDefault()  
        setSS([])              
        let sum = []        
        if(e.target.value === "Sin Encuesta Seleccionada" || e.target.value === "Seleccione la encuesta" || e.target.value === null || e.target.value === undefined) {return}    
            let sec = []
            if(userData.permiso === "admin" || userData.permiso === "gerente" /* || userData.permiso === "user" */)/* permisos para los datos de usuario, aun falta investigar para que son estos permisos */   
            { sec = secciones}
            else {
              sec = secciones.filter(el=>{ return userData.secciones.split(",").indexOf(el.id.toString()) > -1 })
            }
            let enc = encuestas.find(el => el.id === Number(e.target.value))
            let tem = sec.filter(s => {return s.encuesta_id === enc.id})
            if(enc) { 
                axios.get(API_URL+'/respuesta', {params:{encuesta_id:enc.id, periodo_id: sPeriods }}).then(res=>{                   
                    let temp = res.data             
                    let temp2 = [] 
                    axios.get(API_URL + '/video', { params: { client_id:userData.cliente_id} }).then((response) => { 
                        let videos = response.data.filter(v => {return v.estado !== "inactivo"})                     
                        if(userData.permiso === "admin" || userData.permiso === "gerente" || userData.permiso === "user") {temp2 = temp}/* faltaba permisos para el usuario */
                        else {
                            let tetemp = temp.filter(el=> { return userData.encuestas.split(",").some(s => {return Number(el.encuesta_id) === Number(s) }) })                   
                            temp2 = tetemp.filter(p => { return videos.some(v => { return (p.video_id === v.id || p.video_id === null) }) }) }                
                            temp2.forEach((r)=>{                         
                                if(r.tipo_pregunta === 'escala-tri') {
                                    if(r.respuesta === 'malo') { r.Value=0;r.Segmento= preguntas.find(p => p.id === r.pregunta_id) ? preguntas.find(p => p.id === r.pregunta_id).segmento : "" ; sum.push(r)}
                                    if(r.respuesta === 'regular') { r.Value =50; r.Segmento= preguntas.find(p => p.id === r.pregunta_id) ? preguntas.find(p => p.id === r.pregunta_id).segmento : "" ;sum.push(r)}
                                    if(r.respuesta === 'excelente') { r.Value = 100; r.Segmento = preguntas.find(p => p.id === r.pregunta_id) ? preguntas.find(p => p.id === r.pregunta_id).segmento : "" ;sum.push(r)}
                                }
                                if(r.tipo_pregunta === "escala") { r.Value = ((r.respuesta*100)/r.valor_max); r.Segmento = preguntas.find(p => p.id === r.pregunta_id) ? preguntas.find(p => p.id === r.pregunta_id).segmento : "" ;sum.push(r)}
                                if(r.tipo_pregunta === "thumbs") {
                                    if(r.respuesta === "si") {r.Value = 100; r.Segmento = preguntas.find(p => p.id === r.pregunta_id) ? preguntas.find(p => p.id === r.pregunta_id).segmento : ""; sum.push(r)}
                                    else if(r.respuesta === "no"){ r.Value = 0; r.Segmento = preguntas.find(p => p.id === r.pregunta_id) ? preguntas.find(p => p.id === r.pregunta_id).segmento : "";sum.push(r)}
                                }
                                if(r.tipo_pregunta === "stars"){ r.Value = ((r.respuesta*100)/5); r.Segmento = preguntas.find(p => p.id === r.pregunta_id) ? preguntas.find(p => p.id === r.pregunta_id).segmento : ""; sum.push(r)}
                                if(r.tipo_pregunta === "nps" || (r.tipo_pregunta === "npsna" && r.respuesta !== "na") ){ r.Value = ((r.respuesta*100)/10); r.Segmento = preguntas.find(p => p.id === r.pregunta_id) ? preguntas.find(p => p.id === r.pregunta_id).segmento : ""; sum.push(r)}
                                if(r.tipo_pregunta === "smiles"){ r.Value = (r.respuesta*100)/5; r.Segmento = preguntas.find(p => p.id === r.pregunta_id) ? preguntas.find(p => p.id === r.pregunta_id).segmento : ""; sum.push(r)}
                                if(r.tipo_pregunta === "audit") {
                                    if(r.respuesta === "si") { r.Value = 100; r.Segmento = preguntas.find(p => p.id === r.pregunta_id) ? preguntas.find(p => p.id === r.pregunta_id).segmento : ""; sum.push(r)}
                                    else if(r.respuesta === "no"){ r.Value = 0; r.Segmento = preguntas.find(p => p.id === r.pregunta_id) ? preguntas.find(p => p.id === r.pregunta_id).segmento : ""; sum.push(r)}}
                                })                      
                            setRespuestas(sum)                     
                            })            
                        })                                   
                setE(enc); setS(tem); 
        }             
        if(userData.permiso === "encuestador") {                                            
            let thePeriod = periodos.find(p => {return Number(p.id) === Number(enc.periodo_encuesta)})                      
            let today = new Date()                                
            let theEnd = new Date(thePeriod.periodo_fin.replace(/-/g, '\/').replace(/T.+/, ''))      
            if (((theEnd.getDate() - today.getDate() < 0 || theEnd.getMonth() - today.getMonth() < 0  || theEnd.getFullYear() - today.getFullYear() < 0) && thePeriod.validez === "cerrado")) {
                axios.get(API_URL + '/joint', { params: { encuesta_id: e.target.value} }).then((response) => {                
                let arr = response.data.sort(function(a,b){return b.periodo_id - a.periodo_id;})                  
                    setEncPer(periodos.find(p => {return Number(p.id) === Number(arr[0].periodo_id)}))
                })
                }
            else { setEncPer(thePeriod);}                
            }
    }
    const handleCity = (e) => {  
        e.preventDefault()        
        let sucursales = puntos.filter(s => {return Number(s.ciudad_id) === Number(e.target.value)})        
        setSuc(sucursales)
    }
    function getClass(val) {
        let x = Number(val)
        if(isNaN(x)|| val === null){return "Fondo-Blank"}
        if(x < paramData.values.amarillo) {return "Fondo-R"}
        if(x < paramData.values.verde) {return "Fondo-A"}
        if(x >= paramData.values.verde) {return "Fondo-V"}
    }    
    const startEncuesta = () => {        
        if(enc !== undefined && !isNaN(eSec) && !isNaN(eSuc) ) { newLocation("../../../encuesta/0/"+enc.id+"/"+eSec+"/"+eSuc+"/"+userData.id) }
        else { Toaster("danger", "por favor llene todos los campos") }
    }
    if(enc) {
        let tont = logData.id === 49 ? segmentos.filter(s => s.encuesta_id === enc.id)
        .filter(s => {return AttrMens.some(a => {return s.encuesta_id === enc.id && s.id === a.segmento_id && Number(a.y) > 0 })}) : segmentos.filter(s => s.encuesta_id === enc.id)
    Zeries = tont.map(s => {return { data: AttrMens.filter(a => a.segmento_id === s.id).map(d => {return (d.y)}), type: 'bar',barGap: 0, name: s.nombre_segmento, label: labelOption, }})
    }
    function MainDashboard() {            
        if(userData.permiso === "encuestador") {                            
            return <div className="inicio-container">                
                    <Form>                    
                    <Row className="mt-5 justify-center">
                        <h2 className="m-3 center-text short-text" >Seleccione los parametros donde se aplicará la encuesta</h2>
                    </Row>                                          
                    {Sucursales.length > 0 ? null:<h6 className="danger-text center-text">No se encontraron sucursales para evaluar.</h6>}
                        <Row className="Barra">
                        <Form.Control className="Barra-child m-2" value={userData.usuario} readOnly  {...register("encuestado")}/>
                        <Row className="Barra">
                            <Form.Select className="Barra-child mb-2" onChange={(e) => {handleEncuesta(e)}}>
                                <option value={null}>Seleccione la encuesta</option>                            
                                {encuestas.length > 0 ? encuestas.filter(el=>{ return (userData.encuestas.split(",").indexOf(el.id.toString()) > -1)}) //&& el.tipo_encuesta !== "mystery"
                                .map((sucursal) => <option value={sucursal.id}>{sucursal.nombre_encuesta}</option>
                                ) : null}
                            </Form.Select>
                        </Row>
                        <Row className="Barra">
                            <Form.Select className="Barra-child mb-2 " onChange={(e) => {setESUC(e.target.value)}}>
                                <option value={null}>Seleccione una sucursal</option>
                                {Sucursales.length > 0 ? Sucursales.filter(el=>{ return userData.puntos.split(",").indexOf(el.id.toString()) > -1})
                                .map((sucursal) => <option value={sucursal.id}>{sucursal.nombre_sucursal}</option>
                                ) : null}
                            </Form.Select>
                        </Row>                            
                        <Row className="Barra">
                            <Form.Select className="Barra-child mb-2" onChange={(e) => {setESEC(e.target.value)}}>
                                <option value={null}>Seleccione una seccion</option>
                                {oSecciones.length > 0 ? oSecciones.filter(el=>{ return userData.secciones.split(",").indexOf(el.id.toString()) > -1})
                                .map((sucursal) => <option value={sucursal.id}>{sucursal.nombre_seccion}</option>
                                ) : null}
                        </Form.Select>
                        </Row>
                    </Row>
                    <Row className="Barra">
                        <Button className="m-3 Barra-button" onClick={() => startEncuesta()} disabled={Sucursales.length > 0 ? false : true}>Empezar</Button>                            
                    </Row>
                    </Form>
                <div className="floating-text text-center">
                {enc && !isNaN(eSec) && !isNaN(eSuc) ? <p>
                        <h6>{encPer.nombre_periodo}</h6>
                        <h6>{enc.nombre_encuesta}</h6>
                        <h6>{secciones.find(s => {return s.id === Number(eSec)}).nombre_seccion}</h6> 
                        <h6>{Sucursales.find(s => {return s.id === Number(eSuc)}).nombre_sucursal}</h6> 
                        <h6>Encuestas efectivas:</h6>
                        <h4>{reportes.length > 0 ? 
                        reportes.filter(d => respuestas.some(r => {                             
                            return Number(r.Reporte_id) === Number(d.id) &&
                            Number(d.sucursal) === Number(eSuc) &&
                            Number(d.encuesta_id) === Number(enc.id) &&
                            Number(r.Seccion_id) === Number(eSec) && 
                            Number(d.periodo_id) === Number(encPer.id)
                        })).length : "n/a"
                        }</h4>    
                    </p>: null}
                    </div>
            </div>        
        }
        if(userData.permiso === "labor") {
            return <div className="inicio-container">
                <h3 className="m-4">Innova</h3>
                <div className="Gallery">
                    <Row className="full-width">
                        <Col>
                            <img className="place-image m-4 mt-2" src={placeimage} alt="" />
                        </Col>
                        <Col className="mt-4">
                            <p>Nombre: {userData.nombres+" "+userData.apellidos}</p>
                            <p>Numero de identificacion: {userData.usuario}</p>
                            <p>Puesto: {userData.cargo}</p>
                            <p>Gerencia: {userData.gerencia}</p>
                            <p>Periodo: Octubre 2023</p>
                        </Col>
                    </Row>
                    <Row className="mt-2">  
                        <h6 className="m-3">Competencias</h6>                                                                     
                        <Col>
                 
                        </Col>
                    </Row>
                    <Row className="m-4">
                        <Col>
                            <Table striped>
                                <thead>
                                    <tr>
                                        <th>Competencia</th>
                                        <th>Colaborador</th>
                                        <th>Jefe</th>
                                        <th>Autoevaluación</th>
                                        <th>ideal</th>
                                        <th>Puntaje</th>
                                        <th>%cumplimiento</th>                                
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Comunicación</td>
                                        <td>4.83</td>
                                        <td>4.33</td>
                                        <td>4.67</td>
                                        <td>5.0</td>
                                        <td>4.51</td>
                                        <td>90.20%</td>   
                                    </tr>
                                    <tr>
                                        <td>Digital Mindset</td>
                                        <td>4.67</td>
                                        <td>4.33</td>
                                        <td>4.67</td>
                                        <td>5.0</td>
                                        <td>4.47</td>
                                        <td>89.40%</td>   
                                    </tr>
                                    <tr>
                                        <td>Orientación al cliente</td>
                                        <td>4.83</td>
                                        <td>4.00</td>
                                        <td>5.00</td>
                                        <td>5.0</td>
                                        <td>4.35</td>
                                        <td>87.00%</td>   
                                    </tr>
                                </tbody>
                            </Table>
                        </Col>
                        <Col></Col>                        
                    </Row>
                </div>
            </div>
        }
        if(userData.permiso === 'admin' && changeView === false) {
            return <div className="inicio-container">
            {/* <h3 className="m-4">Selecciona un Cliente</h3>     */} {/* opcional,dependiendo el cliente */}       
            <div className="Gallery">
            <h3 className="m-2">Selecciona un Cliente</h3>
                    <InputGroup className="m-3" style={{width:'auto'}}>
                        <Form.Control onChange={(e) => searchItems(e.target.value)} placeholder="Buscar" />    
                        {userData.permiso === "admin" && userData.subscription !== "super " ? <Button className="App-button" disabled={logData.id<=0} onClick={() => {setCV(!changeView);Clean(logData.id)}}>Graficas.</Button>:null}
                        {
                          /*  <Button onClick={() => {
                                let formData = {}; formData.client_id = logData.id;
                                axios.post(API_URL+'/respuestapregunta', formData )
                            }}> Execute </Button>*/
                        }
                    </InputGroup>
                <div className="gallery-flex">
                    {userData && userData.subscription === "trial" ?  null : <div className="gallery-item mb-4" onClick={handleShow} > Nuevo Cliente </div>}
                    {FC.map(c => {
                        return <>                        
                        <div className={logData && logData.id === c.id ? "gallery-item-active mb-4" : "gallery-item mb-4"}
                        style={{backgroundImage:'url("https://api.medicionanalytic.com/medicionapi/storage/app/public'+c.logo+'")'}}
                        onClick={() => handleClientChange(c,null)}>                        
                        <span className="Sub-title">{c.nombre_cliente} <BiEditAlt className="Top-item" onClick={() => handleClientChange(c,true)} /></span>
                        </div>
                        </>
                    })}
                </div>
                {userData.subscription === "trial" ? 
                <p className="text-center">
                    <span>Después de seleccionar tu empresa, quedando un marco violeta.</span> <br/>
                    <span>Sigue seleccinando el apartado de encuestas en las pestañas de arriba.</span>
                </p>                   
                :null}
            </div>
        </div>
        }
        if(userData.permiso === 'user' || userData.permiso === 'gerente' || changeView === true) {                                                                            
        return <div className="inicio-container">   
            <Row className="client-view m-0 pb-0"> {/*linea de control */}
                <Col className="flex mb-2">
                    <h3 className="tit_panel">Panel de control</h3>
                    {userData.permiso === "admin" && userData.subscription !== "super " ? <Button className="App-button m-3 mb-0 mt-0 " onClick={() => setCV(!changeView)}>Volver</Button>:null}
                </Col>                
            </Row>
            <Row className="client-view"> 
                <Col className="mt-0 mb-0 m-1">
                    <Form onSubmit={handleSubmit(SetFilters)}>
                        <Row className="mb-1">
                            <Col>
                                <MultiSelect className="inicio-search"
                                overrideStrings={{
                                    "allItemsAreSelected": "Todos los Periodos Seleccionados.",
                                    "clearSearch": "Limpiar busqueda",
                                    "clearSelected": "Limpiar Seleccion",
                                    "noOptions": "Sin opciones",
                                    "search": "Buscar",
                                    "selectAll": "Todos",
                                    "selectAllFiltered": "Todos (Filtrado)",
                                    "selectSomeItems": "Seleccionar Periodo",
                                    "create": "Create",}
                                  }
                                 options={periodos}
                                 value={sPeriods}
                                 onChange={setSP}
                                 labelledBy="Select"
                                />
                            </Col>
                            <Col>
                                <Form.Select className="select-search" onChange={handleEncuesta}>
                                    <option value={null}>Sin Encuesta Seleccionada</option>
                                    {userData.permiso === "admin"?
                                        encuestas.map(enc => {return <option value={enc.id}>{enc.nombre_encuesta}</option>}) :   
                                        encuestas.filter(el=>{ return userData.encuestas.split(",").indexOf(el.id.toString()) > -1 })
                                        .map(enc => {return <option value={enc.id}>{enc.nombre_encuesta}</option>})}
                                </Form.Select>
                            </Col>                        
                            <Col>
                                <MultiSelect className="inicio-search"
                                 overrideStrings={{
                                    "allItemsAreSelected": "Todo Seleccionado.",
                                    "clearSearch": "Limpiar busqueda",
                                    "clearSelected": "Limpiar Seleccion",
                                    "noOptions": "Sin opciones",
                                    "search": "Buscar",
                                    "selectAll": "Todos",
                                    "allItemsAreSelected": "Todas las Secciones Seleccionadas.",
                                    "selectSomeItems": "Seleccionar Secciones",
                                    "create": "Create",}
                                  }
                                 options={oSecciones}
                                 value={sSecciones}
                                 onChange={setSS}
                                 labelledBy="Select"
                                />
                            </Col>
                            <Col>
                                <MultiSelect className="inicio-search"
                                 overrideStrings={{
                                    "allItemsAreSelected": "Todos los Puntos Seleccionados.",
                                    "clearSearch": "Limpiar busqueda",
                                    "clearSelected": "Limpiar Seleccion",
                                    "noOptions": "Sin opciones",
                                    "search": "Buscar",
                                    "selectAll": "Todos",
                                    "selectAllFiltered": "Todos (Filtrado)",
                                    "selectSomeItems": "Seleccionar Puntos",
                                    "create": "Create",}
                                  }
                                  options={userData.permiso === "admin" ?
                                    Sucursales   :
                                    Sucursales.filter(el => {return  userData.puntos.split(",").indexOf(el.id.toString()) > -1})}
                                 value={sPuntos}
                                 onChange={setSPtos}
                                 labelledBy="Select"
                                />
                            </Col>
                        </Row>
                        {logData && Number(logData.id) === 49 ? 
                        <Row className="mb-1">
                            <Col>
                                <Form.Select className="inicio-search" onChange={handleCity} >
                                    <option value={null}>Sin Ciudad Seleccionada</option>
                                    {cities.length > 0 ? cities.map(enc => {return <option value={enc.id}>{enc.nombre_ciudad}</option>}):null}
                                </Form.Select>
                            </Col>
                            <Col></Col>
                            <Col></Col>
                            <Col></Col>
                        </Row> :null}
                        <Row className="sm-center">
                            <Button type="submit" className="App-button m-1">Aplicar filtros <BiSearchAlt/></Button>
                        </Row>
                    </Form>
                </Col>        
            </Row> {/*segunda linea */}
            <Row className="client-view">
                <Col className="collumn-alt cont_base bkg-white m-1" onClick={() => newLocation("/app/reportes/reporte-encuestas")}>
                    <h4 className="m-2">Resumen General</h4>
                    <EChart className="Echart" renderer={'canvas'} tooltip={{trigger:"item"}}                        
                        series={[
                            {
                              type: 'gauge',
                              startAngle: 180,
                              endAngle: 0,
                              center: ['50%', '70%'],
                              radius: '100%',
                              min: 0,
                              max: 1,
                              splitNumber: 8,
                              axisLine: {
                                lineStyle: {
                                  width: 6,
                                  color: [
                                    [(Number(paramData.values.amarillo)/100), 'red'],
                                    [(Number(paramData.values.verde)/100), 'yellow'],
                                    [1, 'green'],                                    
                                  ]
                                }
                              },
                              pointer: {
                                icon: 'path://M12.8,0.7l12,40.1H0.7L12.8,0.7z',
                                length: '12%',
                                width: 20,
                                offsetCenter: [0, '-60%'],
                                itemStyle: {
                                  color: 'auto'
                                }
                              },
                              axisTick: {
                                length: 12,
                                lineStyle: {
                                  color: 'auto',
                                  width: 2
                                }
                              },
                              splitLine: {
                                length: 20,
                                lineStyle: {
                                  color: 'auto',
                                  width: 6
                                }
                              },
                              axisLabel: {
                                color: '#464646',
                                fontSize: 10,
                                distance: -60,
                                rotate: 'tangential',
                                formatter: function (value) {
                                  if (value === 0.750) {
                                    return 'Grade B';
                                  } else if (value === 0.500) {
                                    return 'Grade C';
                                  } else if (value === 0.250) {
                                    return 'Grade D';
                                  }
                                  return '';
                                }
                              },
                              title: {
                                offsetCenter: [0, '20%'],
                                fontSize: 15
                              },
                              detail: {
                                fontSize: 20,
                                offsetCenter: [0, '-5%'],
                                valueAnimation: true,
                                formatter: function () {
                                  return Math.round(mainValue) + '%';
                                },
                                color: '#000' //letter color
                              },
                              data: [
                                {
                                  value: Math.round(mainValue)/100, //here
                                  name: 'Grade Rating'
                                }
                              ]
                            }
                          ]}                        
                    />
                </Col>
                <Col className="collumn-alt cont_base bkg-white m-1">
                    <h4 className="m-2">Historico</h4>                          
                        <EChart className="Echart" renderer={'canvas'} tooltip={{trigger:"item"}}
                            legend={{bottom:'15%',left:'center'}}                                    
                            xAxis={{
                                type: 'category',
                                data: MG.map(dat => {return dat.x}),                             
                                axisLabel: { interval: 0, rotate: 35 }
                              }}
                              yAxis={{type: 'value'}}
                              series={[
                                { data: MG.map(dat => {return {value:Number(dat.y), itemStyle: { color: getColors(dat.y)}}}),
                                  type: 'bar',
                                  label: labelOption,}
                              ]}
                            />
                </Col>
                <Col className="collumn-alt cont_base bkg-white m-1" onClick={() => newLocation("/app/reportes/reporte-puntos")}>
                    <h4 className="m-2">Ranking Puntos</h4>                         
                        <EChart className="Echart" renderer={'canvas'}
                            tooltip={{trigger:"item"}}                            
                            xAxis= {{
                                type: 'category',
                                axisTick: { show: false },
                                data: PG.sort((a,b) => a.y - b.y).map(p => {return p.x}),
                                axisLabel: { interval: 0, rotate: 35 }
                              }}
                            yAxis={{type: 'value'}}
                              series= {[
                                { data: PG.sort((a,b) => a.y - b.y).map(p => {return {value:Number(p.y), itemStyle: { color: getColors(p.y)}}}),
                                  type: 'bar',                                  
                                  label: labelOption,                                  
                                  backgroundStyle: {
                                    color: 'rgba(180, 180, 180, 0.2)'
                                  }
                                }
                              ]}/>
                </Col>
            </Row>    
            <Row className="client-view"> {/*tercera linea*/}
               { logData && Number(logData.id) !== 71 ? <Col className="collumn-alt cont_base bkg-white m-1">                
                <h4 className="m-2">Detalle de atributos </h4>                          
                <EChart className={segmentos.filter(s => {return AttrMens.some(a => {return s.encuesta_id === enc.id && s.id === a.segmento_id && Number(a.y) > 0 })}).length > 10 ? "Echart-wide":"Echart-half"} renderer={'canvas'} tooltip={{trigger:"item"}} 
                            legend={{bottom:'0%',left:'center', itemGap: 5}}                            
                            xAxis= {{type: 'category',data: PS.map(d => {return d.nombre_corto}), axisLabel: { interval: 0 } }}
                            yAxis={{type: 'value'}}
                            series= {Zeries}                            
                            />
               </Col> : logData && Number(logData.id) !== 25 ? <Col className="collumn-alt cont_base bkg-white m-1" onClick={() => newLocation("/app/reportes/reporte-secciones")}>
                <h4 className="m-2">Resumen de Secciones</h4>                
                    <EChart className="Echart-half-high"  renderer={'canvas'} tooltip={{trigger:'item'}}                                                                                                    
                        xAxis= {{ type: 'value'}}
                        yAxis= {{ type: 'category',axisLabel: { interval: 0, rotate: 15, fontSize: 12 }, data: FS.sort((a,b) => Number(a.Result) - Number(b.Result)).map(dat => {return dat.nombre_seccion}),}}                          
                        series={[{ type: 'bar',                             
                            data: FS.sort((a,b) => Number(a.Result) - Number(b.Result))
                            .map(dat => {return {value:Number(dat.Result), itemStyle: { color: getColors(dat.Result)}}}),
                            label: labelOption2
                        }]}                        
                    />
               </Col> : null} 
               <Col onClick={() => newLocation("/app/reportes/reporte-puntos")}>   
               <h4 className="m-2">Detalle de puntos</h4>            
               <Table className="App-Table front-table" striped responsive="md" hover size="sm">
                    <thead>
                        <tr>
                            <th className="table-th sticky-col first-col">Sucursal</th>                        
                            {PS.filter(pnt => {if(dataFiltered.filter(d => d.Periodo_id === pnt.id).length > 0) {return pnt}})
                            .map(p => { return <th className="table-th sticky-col first-col">{p.nombre_periodo}</th>})}
                        </tr>
                    </thead>
                    <tbody>
                        {FP.sort(function(a,b){return dataFiltered.filter(d => d.Punto_id === b.id).reduce(function (acc, obj) { return acc + Number(obj.y); }, 0)/dataFiltered.filter(d => d.Punto_id === b.id).length - dataFiltered.filter(d => d.Punto_id === a.id).reduce(function (acc, obj) { return acc + Number(obj.y); }, 0)/dataFiltered.filter(d => d.Punto_id === a.id).length })
                        .map((p, index) => {                            
                                return <tr>
                                <td className="sticky-col  text-center">
                                    {p.nombre_sucursal}
                                </td>
                        {PS.filter(pnt => {if(dataFiltered.filter(d => d.Periodo_id === pnt.id).length > 0) {return pnt}})
                        .map(per =>{                            
                                 return <td>{
                                    dataFiltered.filter(d => d.Punto_id === p.id && Number(d.Periodo_id) === Number(per.id))                                    
                                    .map(d => {                                                        
                                        if(!isNaN(d.y)) 
                                            {return <td className={getClass(d.y)}>{d.y + '%'}</td>}
                                        else {return <td className={getClass(null)}>{'NaN%'}</td>}
                                        })
                                    }</td>                             
                                })}
                            </tr>                            
                        })}
                    </tbody>
                </Table>
               </Col>
               {logData && (Number(logData.id) !== 25 && Number(logData.id) !== 71) ? <Col className="collumn-alt bkg-white m-1" onClick={() => newLocation("/app/reportes/reporte-secciones")}>                
                    <h4 className="m-2">Resumen de Secciones</h4>                
                    <EChart className={logData && (Number(logData.id) !== 25 && Number(logData.id) !== 71) ? "Echart-half-high" : "Echart-wide"}  renderer={'canvas'} tooltip={{trigger:'item'}}                                                                                                    
                        xAxis= {{ type: 'value'}}
                        yAxis= {{ type: 'category',axisLabel: { interval: 0, rotate: 15, fontSize: 12 }, data: FS.sort((a,b) => Number(a.Result) - Number(b.Result)).map(dat => {return dat.nombre_seccion}),}}                          
                        series={[{ type: 'bar',                             
                            data: FS.sort((a,b) => Number(a.Result) - Number(b.Result))
                            .map(dat => {return {value:Number(dat.Result), itemStyle: { color: getColors(dat.Result)}}}),
                            label: labelOption2
                        }]}                        
                    />
               </Col> : null}           
            </Row>     
        </div>
        }
    }
    const getColors = (val) => {
        let x = Number(val)        
        if(x < paramData.values.amarillo) {return "lightcoral"}
        if(x < paramData.values.verde) {return "yellow"}
        if(x >= paramData.values.verde) {return "yellowgreen"}
    }
    function Toaster(variant, message) { setToast({ show: true, variant: variant, message: message }); }
    return <>
     <ToastContainer style={{ position: "fixed", top: "80vh", right: "0vw" }} className="p-3">
            <Toast
                bg={toast.variant}
                onClose={() => setToast({ show: false, variant: "", message: "" })}
                show={toast.show}
                delay={2000}
                autohide>
                <Toast.Header>
                    <strong className="me-auto">Medicion</strong>
                    <small>just now</small>
                </Toast.Header>
                <Toast.Body className="text-white">{toast.message}</Toast.Body>
            </Toast>
        </ToastContainer>
    <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Nuevo Cliente</Modal.Title>
            </Modal.Header>
            <Form onSubmit={handleSubmit(CallModal)}>
                <Modal.Body>
                    <Form.Group className="mb-2">
                        <Form.Label>Nombre del cliente</Form.Label>
                        <Form.Control size="sm" placeholder="Nombre" {...register("nombre_cliente")} />
                        <Form.Text className="text-muted"></Form.Text>
                    </Form.Group>
                    <Form.Group className="mb-2">
                        {/*<Form.Check type="checkbox" label="servicio Basic" {...register("servicio_basic")} />*/}
                        <Form.Check type="checkbox" label="servicio Encuesta" {...register("servicio_encuesta")} />
                        <Form.Check type="checkbox" label="servicio Auditoria" {...register("servicio_audit")} />
                        <Form.Check type="checkbox" label="servicio Mystery" {...register("servicio_mystery")} />
                        <Form.Check type="checkbox" label="servicio Precios" {...register("servicio_shop")}/>
                        <Form.Check type="checkbox" label="servicio Clima Laboral" {...register("servicio_climaLaboral")}/>
                        <Form.Check type="checkbox" label="servicio Desempeño laboral" {...register("servicio_desempeno")}/>
                    </Form.Group>
                    <Form.Group className="mb-2">
                        <Form.Label>logo del cliente</Form.Label>
                        <Form.Control type="file" accept="image/*" {...register("file")}/>
                        <Form.Text className="text-muted">preferiblemente utilizar fotos png de ratio uniforme</Form.Text>
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>                    
                    <Button variant="secondary" onClick={handleClose}>
                        Cerrar
                    </Button>
                    <Button type="submit" variant="primary">
                        Crear
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    <Modal show={showEdit} onHide={handleEditClose}>
        <Modal.Header closeButton>
            <Modal.Title>Actualizar Cliente</Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleSubmit(EditModal)}>
            <Modal.Body>
                <Form.Group className="mb-2">
                    <Form.Label>Nombre del cliente</Form.Label>
                    <Form.Control size="sm" placeholder="Nombre" {...register("nombre_cliente")} />
                    <Form.Text className="text-muted"></Form.Text>
                </Form.Group>
                {userData && userData.subscription !== "trial" ? <Form.Group className="mb-2">
                    {/*<Form.Check type="checkbox" label="servicio Basic" {...register("servicio_basic")} />*/}
                    <Form.Check type="checkbox" label="servicio Encuesta" {...register("servicio_encuesta")} />
                    <Form.Check type="checkbox" label="servicio Auditoria" {...register("servicio_audit")} />
                    <Form.Check type="checkbox" label="servicio Mystery" {...register("servicio_mystery")} />
                    <Form.Check type="checkbox" label="servicio Precios" {...register("servicio_shop")} />
                    <Form.Check type="checkbox" label="servicio Clima Laboral" {...register("servicio_climaLaboral")}/>
                    <Form.Check type="checkbox" label="servicio Desempeño laboral" {...register("servicio_desempeno")}/>                    
                </Form.Group>:null}
                <Form.Group className="mb-2">
                    <Form.Label>logo del cliente</Form.Label>
                    <Form.Control type="file" accept="image/*" {...register("file")}/>
                    <Form.Text className="text-muted">preferiblemente utilizar fotos png de ratio uniforme</Form.Text>
                </Form.Group>
            </Modal.Body>
            <Modal.Footer>
                {userData.subscription === "super" ? <Button variant="danger" onClick={handleDelete}>Eliminar</Button> : null}
                <Button variant="secondary" onClick={handleEditClose}>Cerrar</Button>
                <Button type="submit" variant="primary">Actualizar</Button>
            </Modal.Footer>
            </Form>
        </Modal>
        {loading ? MainDashboard():
        <BarLoader className="m-2" color="#4538D1" width={200} /> }
    </>
}