import { useState, useEffect, useContext, useReducer } from "react";
import {Form, Table, Modal, Button, Toast, ToastContainer, Row, Col, InputGroup, FormGroup} from "react-bootstrap"
import { useForm } from "react-hook-form"
import { FaRegThumbsUp, FaRegThumbsDown, FaPlus, FaStar, FaTrash} from "react-icons/fa";
import { FaRegFaceAngry, FaRegFaceFrown,FaRegFaceMeh, FaRegFaceSmile, FaRegFaceSmileBeam } from "react-icons/fa6"
import {BiTrash, BiEditAlt} from 'react-icons/bi'
import axios from "axios";
import ClientContext from "../../../Context/ClientContext";
import { useNavigate } from "react-router-dom";
import Templates from "./Templates"
import './Preguntas.css'

export default function Preguntas() {
    const templates = Templates    
    const API_URL = 'https://api.medicionanalytic.com/api'
    const {logData} = useContext(ClientContext)
    const newLocation = useNavigate()
    const [_, forceUpdate] = useReducer(x => x + 1, 0);
    const [loadin, setloadin] = useState(false)

    const [encuestas, setEncuestas] = useState([]) //lista encuestas
    const [segmentoP, setSegmentoP] = useState([]) //categoria
    const [secciones, setSeccions] = useState([]) //secciones
    const [sS, setSS] = useState() //seccion a cambiar.
    const [enc,setEnc] = useState({}) //encuesta seleccionada.
    const [MP, setMass] = useState([])

    const [preguntas, setPreguntas] = useState([]) //lista de preguntas de la seccion.
    const [formValues, setFormValues] = useState(templates[0]) // preguntas
    const [edit, setShow] = useState(false) 
    const [viewEliminar, setViewEliminar] = useState(false)
    const [viewAdd, setViewAdd] = useState(false)
    const [viewMAdd, setViewMAdd] = useState(false)
    
    const [toast, setToast] = useState({ show: false, variant: "", message: "" });
    
    const {
        register,
        handleSubmit,
        reset,
        setValue,
        getValues,
        formState: { errors, isValid, isDirty },
      } = useForm({ mode: "onBlur" });

    function Toaster(variant, message) {setToast({ show: true, variant: variant, message: message });}

    const hideEliminar = () => {setViewEliminar(false);updateList();}
    const showEliminar = (pregunta) => {
        reset({
            id:pregunta.id,
            tipo_pregunta: pregunta.tipo_pregunta,
            titulo: pregunta.titulo,                        
        })
        setFormValues(pregunta)
        setViewEliminar(true)
    }

    const handleEditClose = () => {setShow(false);updateList()}
    const handleEditShow = (pregunta) => {                
        if(pregunta.options !== null) {pregunta.options = JSON.parse(pregunta.options)}                
        reset({
            id:pregunta.id,
            tipo_pregunta: pregunta.tipo_pregunta,
            titulo: pregunta.titulo,
            titulo_alt: pregunta.titulo_alt,
            valor_min: pregunta.valor_min,
            valor_max: pregunta.valor_max,
            segmento: pregunta.segmento == null ? "" : Number(pregunta.segmento),
            aplica: pregunta.aplica,
            aplica_peso: pregunta.aplica_peso,
            peso: pregunta.peso,
            pausa : pregunta.pausa,
            orden : pregunta.orden,
            options : pregunta.options,
        })
        setFormValues(pregunta)
        setShow(true)
    }

    const hideAdd = () => {setViewAdd(false);updateList()}
    const hideMAdd = () => {setViewMAdd(false);updateList()}
    const showAdd = () => setViewAdd(true)
    const showMAdd = () => setViewMAdd(true)    

    function updateList() {
        formValues.options = [{opt: ""}]
        axios.get(API_URL + '/pregunta', {params: {seccion_id:sS.id}}).then((response) => {
            setPreguntas(response.data)            
            setFormValues({})
            reset({
                id:null,
                tipo_pregunta: 'texto',
                titulo: null,
                opt:"",
                valor_min: 1,
                valor_max: null,
                segmento: "",
                aplica: 0,
                aplica_peso: 0,
                peso: null,
                pausa : 0,
                orden: 0,
            })
        })
    }

    const setHeaders = () => {
        let token = window.localStorage.getItem("sessionKey");
        const headers = {
                Authorization: `Bearer ${token}`,
                Accept: "application/json",
            };
        return headers;}
/*
    const handleImport = ($event) => {
        const files = $event.target.files;
        let row           
        let pregs = []     
        if (files.length) {
            const file = files[0];
            const reader = new FileReader();
            reader.onload = (event) => {
                const wb = read(event.target.result);
                const sheets = wb.SheetNames;                
                if (sheets.length) 
                {   row = utils.sheet_to_json(wb.Sheets[sheets[0]]);                    
                    let pregunta = {}
                    row.forEach((r,i) =>{
                        if(i > 0){
                            pregunta = {
                                tipo_pregunta : "thumbs",
                                titulo: r.__EMPTY_2,
                                segmento: r.__EMPTY_1,
                                aplica: 1,
                                options: "",
                                seccion_id: sS.id,
                                aplica_peso: 0,
                                peso: null,
                                pausa : 0,      
                            }                        
                            //__EMPTY, __EMPTY_1
                        }                    
                    pregs.push(pregunta)
                })
                pregs.shift() // drop first empty object
                setMass(pregs)                
                }
            }
            reader.readAsArrayBuffer(file);
        }            
    }
    const copyData = () => {        
        let pregs = preguntas
        pregs.map(p => {p.seccion_id = 99})
        let headers = setHeaders()        
        axios.post(API_URL + '/pregunta/CU', pregs, {headers}).then((response)=> {
            Toaster("success", response.data.message)            
            setloadin(false)            
            hideMAdd()
        })        
    }*/

    useEffect(()=>{
        axios.get(API_URL + '/encuesta/all', { params: { client_id:logData.id } }).then(
            function(response) {
                if(typeof response.data === 'object') { let temp = Object.keys(response.data).map((key) => response.data[key]); setEncuestas(temp)    }
                else { setEncuestas(response.data) }
            })
        axios.get(API_URL+'/segmento', { params: { client_id:logData.id, tipo:"pregunta" } }).then((response) =>
           { setSegmentoP(response.data)
            }
        )

        if(sS && sS.id !== undefined) {
            axios.get(API_URL + '/pregunta', {params: {seccion_id:sS.id}}).then((response) => {
                setPreguntas(response.data)                    
            })    
        }
    },[toast])

    const handleChange = e => {
        e.preventDefault();
        axios.get(API_URL + '/seccion', { params: {encuesta_id:e.target.value} }).then(
            function(response) {
                setSS()
                setSeccions(response.data)
            })
        if(!isNaN(e.target.value)) {
            setEnc(encuestas.find(en => {return Number(en.id) === Number(e.target.value)}))    
        } else {setEnc({})}
    }
    const handleSectionChange = e => {
        e.preventDefault();            
        let temp = secciones.find((seccion) => seccion.id === Number(e.target.value))
        axios.get(API_URL + '/pregunta', {params: {seccion_id:e.target.value}}).then((response) => {
            setPreguntas(response.data)
            setSS(temp);
        })
    }
    const handlePreguntaChange = e => {
        e.preventDefault();
        let temp = templates.find((template) => template.tipo_pregunta == e.target.value)
        setValue("tipo_pregunta", e.target.value)
        setFormValues(temp)        
        forceUpdate()
    }

    const agregarPregunta = (data,e) => {
        e.preventDefault();
        setloadin(true)
        data.seccion_id = sS.id
        delete data.opt
        data.options = JSON.stringify(formValues.options)        
        axios.post(API_URL + '/pregunta', data).then((response)=> {
            Toaster("success", response.data.message)            
            setloadin(false)
            hideAdd()                  
        })
    }

    const agregarMassPregunta = () => {    
        let headers = setHeaders()            
        setloadin(true)
        axios.post(API_URL + '/pregunta/CU', MP, {headers}).then((response)=> {
            Toaster("success", response.data.message)            
            setloadin(false)            
            hideMAdd()
        })
    }

    const actualizarPregunta = (data,e) => {
        e.preventDefault();        
        let headers = setHeaders()
        if(data.pausa === true) {data.pausa = 1}
        else {data.pausa = 0}
        data.seccion_id = sS.id
        //delete data.opt
        data.options = JSON.stringify(data.options)        
        axios.post(API_URL + '/pregunta/update', data, {headers}).then((response)=> {
            Toaster("success", response.data.message)
        })
        handleEditClose()
    }

    const RemovePregunta = () => {
        setloadin(true)
        let i = getValues("id");
        let headers = setHeaders()        
        axios.delete(API_URL + `/pregunta/${i}`, {headers}).then(res => {
            Toaster("success", res.data.message)
            setloadin(false)
            hideEliminar()
        })
    }
     
    function preguntaSwitch(preg) {                    
        switch(preg.tipo_pregunta) {
            case 'escala-tri':
                return <InputGroup className="mt-2">
                            <Button variant="outline-danger">malo</Button>
                            <Button variant="outline-warning">regular</Button>
                            <Button variant="outline-success">excelente</Button>
                        </InputGroup>
            case 'escala': 
            return <InputGroup className="mt-2">
                        <InputGroup.Text>Escala</InputGroup.Text>
                        <Form.Control type="number" min={0} max={1} defaultValue={1} {...register("valor_min")}/>
                        <Form.Control type="number" defaultValue={5} {...register("valor_max")} />
                    </InputGroup>                        
            default: return <FormGroup>
                    <Form.Control readOnly placeholder="texto de la respuesta" />
                </FormGroup>
            case "comentario": return <FormGroup>
                    <textarea className="form-control" placeholder="Texto del comentario" readOnly rows={4}/>
                </FormGroup>
            case "mail": return <FormGroup>
                    <Form.Control readOnly placeholder="Email de respuesta" />
                </FormGroup>
            case "info": return <FormGroup>
                    <Form.Control className="mb-1" readOnly placeholder="Nombre" />                    
                    <Form.Control className="mb-1" readOnly placeholder="Telefono" />                    
                </FormGroup>
            case "stars": return <FormGroup>
                    <p className="flex">
                        <div className="form-option m-1"><FaStar className="gold"/></div>
                        <div className="form-option m-1"><FaStar className="gold"/></div>
                        <div className="form-option m-1"><FaStar className="gold"/></div>
                        <div className="form-option m-1"><FaStar className="gold"/></div>
                        <div className="form-option m-1"><FaStar className="gold"/></div>
                    </p>
                </FormGroup>
            case "smiles": return <FormGroup>
            <p className="flex">
                <div className="form-option m-1"><FaRegFaceAngry className="red"/></div>
                <div className="form-option m-1"><FaRegFaceFrown className="orange"/></div>
                <div className="form-option m-1"><FaRegFaceMeh className="yellow"/></div>
                <div className="form-option m-1"><FaRegFaceSmile className="green"/></div>
                <div className="form-option m-1"><FaRegFaceSmileBeam className="deepgreen"/></div>
            </p>
        </FormGroup>
            case "thumbs": return <FormGroup>
            <p className="flex">
                <div className="form-option m-1"><FaRegThumbsUp/></div>
                <div className="form-option m-1"><FaRegThumbsDown/></div>                
            </p>
        </FormGroup>
            case "audit": return <FormGroup>
            <p className="flex">
                <div className="form-option m-1">Cumple</div>
                <div className="form-option m-1">No Cumple</div>                
                <div className="form-option m-1">N/A</div>                
            </p>
        </FormGroup>
            case "load": return <FormGroup>
                <Form.Control readOnly type="file" accept="doc" placeholder="Email de respuesta" />
            </FormGroup>
            case "select": return <FormGroup>
                <Row className="collumn-option select-height">
                    {preg.options !== undefined && preg.options.length > 0 ? preg.options.map((opt, indes) => {return <p className="flex form-number option-container-modal">
                        <Form.Control className="m-1 mt-2 select-option" size="sm" placeholder="Opcion..." defaultValue={opt.opt} onChange={(e) => {preg.options[indes].opt = e.target.value; setValue("options", preg.options)}}/>                                                                                               
                        <FaTrash className="pointer pt-1" onClick={() => { preg.options.splice(indes, 1);setValue("options", preg.options);forceUpdate()}}/>
                    </p>}):null}
                </Row>                    
                <p>
                    <span className="plus-button-alt" onClick={() => { preg.options.push({opt: ""});forceUpdate()}}> <FaPlus /></span>
                </p>
                </FormGroup>
            case "selectm": return <FormGroup>
                <Row>
                    <Col>
                        <Form.Label>min. respuestas</Form.Label>
                        <Form.Control  defaultValue={1} {...register("valor_min")}/>
                    </Col>
                    <Col>
                        <Form.Label>max. respuestas</Form.Label>
                        <Form.Control  defaultValue={5} {...register("valor_max")} />
                     </Col>
                </Row>
                <Row className="collumn-option select-height">
                {preg.options ? preg.options.map((opt, indes) => {return <p className="flex form-number option-container-modal m-0">                    
                    <Form.Control className="m-1 mt-2 select-option" size="sm" placeholder="Opcion..." defaultValue={opt.opt} onChange={(e) => {preg.options[indes].opt = e.target.value; setValue("options", preg.options)}}/>                                                                       
                    <FaTrash className="pointer pt-1" onClick={() => { preg.options.splice(indes, 1);setValue("options", preg.options);forceUpdate()}}/>                    
                </p>}) : null}
                </Row>
            <p>
                <span className="plus-button-alt" onClick={() => {preg.options.push({opt: ""});forceUpdate()}}> <FaPlus /></span>
            </p>
            </FormGroup>
            case "nps": return <FormGroup>
            <p className="flex">
                <div className="form-option m-1">0</div>
                <div className="form-option m-1">1</div>
                <div className="form-option m-1">2</div>
                <div className="form-option m-1">3</div>
                <div className="form-option m-1">4</div>
                <div className="form-option m-1">5</div>
                <div className="form-option m-1">6</div>
                <div className="form-option m-1">7</div>
                <div className="form-option m-1">8</div>
                <div className="form-option m-1">9</div>
                <div className="form-option m-1">10</div>
            </p>
            </FormGroup>
            case "npsna": return <FormGroup>            
            <p className="flex">
                <div className="form-option m-1">0</div>
                <div className="form-option m-1">1</div>
                <div className="form-option m-1">2</div>
                <div className="form-option m-1">3</div>
                <div className="form-option m-1">4</div>
                <div className="form-option m-1">5</div>
                <div className="form-option m-1">6</div>
                <div className="form-option m-1">7</div>
                <div className="form-option m-1">8</div>
                <div className="form-option m-1">9</div>
                <div className="form-option m-1">10</div>
                <div className="form-option m-3 mt-1 mb-1">N/A</div>
            </p>
            </FormGroup>
            case "fecha": return <FormGroup>
            <p className="flex"><Form.Control className="form-option" type="date" /></p>
            </FormGroup>
        }
    }

    function getVal(value){ if(value === 100) {return "vejde"} else {return "rojjo"} }

    function getPeso(preg){
        let cont = 0
        preg.forEach(p => {
            if(p.aplica_peso > 0) {cont++}
        })
    if(cont === preg.length) {return "si"}
    else {return "no"}
    }

    function getSegmento(segnumber) {
        if(segmentoP.length > 0) {
           let temp = segmentoP.find(p => p.id === Number(segnumber))
           if(temp !== undefined) {return temp.nombre_segmento;}
           else return ""
        }
        else return ""
    }

    return(<>
    <ToastContainer style={{position:"fixed",top:"80vh", right:"0vw"}} className="p-3">
        <Toast
          bg={toast.variant}
          onClose={() => setToast({ show: false, variant: "", message: "" })}
          show={toast.show}
          delay={2000}
          autohide>
          <Toast.Header>
            <strong className="me-auto">Medicion</strong>
            <small>just now</small>
          </Toast.Header>
          <Toast.Body className="text-white">{toast.message}</Toast.Body>
        </Toast>
    </ToastContainer>
    <Modal show={viewEliminar} onHide={hideEliminar}>
    <Modal.Header closeButton>
          <Modal.Title>Eliminar Pregunta</Modal.Title>
        </Modal.Header>
        <Form>
            <Modal.Body>
                <h4>Esta seguro ?</h4>
                <h5>esta acción no puede deshacerse</h5>
            </Modal.Body>
            <Modal.Footer>
                <Button className="App-button-alt" onClick={hideEliminar}>Cerrar</Button>
                <Button onClick={() => RemovePregunta()} disabled={loadin} className="App-button">Eliminar</Button>
            </Modal.Footer>
        </Form>
    </Modal>
    <Modal show={viewAdd} size="lg" onHide={hideAdd}>
    <Modal.Header closeButton>
          <Modal.Title>Añadir Pregunta</Modal.Title>          
        </Modal.Header>
        <Form onSubmit={handleSubmit(agregarPregunta)}>
            <Modal.Body>
                <Row>
                    <Col>
                        <Form.Label>Tipo de pregunta</Form.Label>
                        <Form.Select onChange={handlePreguntaChange} className="mb-2">
                            {logData.subscription !== "trial" ?
                            templates.map((option) =><option value={option.tipo_pregunta}>{option.pregunta}</option>)
                            : templates.filter(opt => opt.trial === "yes").map((option) =><option value={option.tipo_pregunta}>{option.pregunta}</option>)
                        }
                        </Form.Select>
                    </Col>
                    <Col>
                        <Form.Label>Orden de pregunta</Form.Label>
                        <Form.Control className="mb-2" placeholder="0" {...register("orden")}/>    
                    </Col>
                </Row>
                <Form.Control className="mb-2 mt-2" placeholder="Formule la Pregunta..." {...register("titulo", {required:true})}/>
                {formValues && formValues.tipo_pregunta === "texto" ? <Form.Control className="mb-2 mt-2" placeholder="Texto condicional (nps < 8)" {...register("titulo_alt", {required:true})}/> : null}
                {viewAdd === true ? preguntaSwitch(formValues): null}
                <Form.Group>
                    <Form.Label>Segmento</Form.Label>
                    <Form.Select {...register("segmento")}>
                        <option value={""}>sin Segmento</option>
                        {segmentoP.filter(s => {return Number(s.encuesta_id) === Number(enc.id)})
                        .map(s => { return <option value={s.id}>{s.nombre_segmento}</option>})}
                    </Form.Select>
                </Form.Group>
                <InputGroup className="mt-2">
                    <Form.Label className="m-2"> Aplica calculo?</Form.Label>
                    <Form.Check className="mt-2" {...register("aplica")} />
                    <Form.Label className="m-2"> Pausar pregunta?</Form.Label>
                    <Form.Check className="mt-2" {...register("pausa")} />
                    <Form.Label className="m-2"> Aplica peso?</Form.Label>
                    <Form.Check className="mt-2" {...register("aplica_peso")} />
                    <Form.Control className="m-2 mt-0" placeholder="Peso..."  {...register("peso")}/>                
                </InputGroup>
            </Modal.Body>
            <Modal.Footer>
                <Button type="submit" disabled={loadin || !isValid} className="App-button">Añadir</Button>
            </Modal.Footer>
        </Form>
    </Modal>    
    <Modal show={viewMAdd} size="lg" onHide={hideMAdd}>
    <Modal.Header closeButton>
          <Modal.Title>Añadir Pregunta</Modal.Title>          
        </Modal.Header>
        <Form>
            <Modal.Body>
                Carga                
            </Modal.Body>
            <Modal.Footer>
                {/*<Button onClick={() => copyData() }className="App-button">Añadir</Button>*/}
            </Modal.Footer>
        </Form>
    </Modal>    
    <Modal show={edit} size="lg" onHide={handleEditClose}>
    <Modal.Header closeButton>
          <Modal.Title>Modificar Pregunta</Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleSubmit(actualizarPregunta)}>
            <Modal.Body>
                <Form.Control type="hidden" {...register("id")}/>
                <Row>
                    <Col>
                        <Form.Label>Tipo de pregunta</Form.Label>
                        <Form.Select onChange={handlePreguntaChange} className="mb-2">
                            {templates.map((option) =><option selected={getValues("tipo_pregunta") === option.tipo_pregunta} value={option.tipo_pregunta}>{option.pregunta}</option>)}
                        </Form.Select>
                    </Col>
                    <Col>
                        <Form.Label>Orden de pregunta</Form.Label>
                        <Form.Control className="mb-2" placeholder="0" {...register("orden")}/>    
                    </Col>
                </Row>
                <Form.Control className="mb-2 mt-2" placeholder="Formule la Pregunta..." {...register("titulo")}/>
                {formValues && formValues.tipo_pregunta === "texto" ? <Form.Control className="mb-2 mt-2" placeholder="Texto condicional (nps < 8)" {...register("titulo_alt", {required:true})}/> : null}
                {edit === true ? preguntaSwitch(formValues):null}
                <Form.Group>
                    <Form.Label>Segmento</Form.Label>
                    <Form.Select {...register("segmento")}>
                        <option value={""}>sin Segmento</option>
                        {segmentoP.filter(s => {return Number(s.encuesta_id) === Number(enc.id)})
                        .map(s => { return <option value={s.id}>{s.nombre_segmento}</option>})}
                    </Form.Select>
                </Form.Group>
                <InputGroup className="mt-2">
                    <Form.Label className="m-2"> Aplica calculo?</Form.Label>
                    <Form.Check className="mt-2" {...register("aplica")} />
                    <Form.Label className="m-2"> Pausar pregunta?</Form.Label>
                    <Form.Check className="mt-2" {...register("pausa")} />
                    <Form.Label className="m-2"> Aplica peso?</Form.Label>
                    <Form.Check className="mt-2" {...register("aplica_peso")} />
                    <Form.Control className="m-2 mt-0" placeholder="Peso..."  {...register("peso")}/>                
                </InputGroup>
            </Modal.Body>
            <Modal.Footer>
                <Button type="submit" className="App-button" onClick={handleEditClose}>Actualizar</Button>
            </Modal.Footer>
        </Form>
    </Modal>
    <div className="content-container">
        <div className="content-header"><h4>Encuesta</h4></div>
        <Form>
            <Row className="mb-2">
                <Form.Group as={Col}>
                    <Form.Select onChange={handleChange}>
                        <option>Seleccionar encuesta...</option>
                        {encuestas.map((encuesta) => <option value={encuesta.id}>{encuesta.nombre_encuesta}</option>)}
                    </Form.Select>
                </Form.Group>
                <Form.Group as={Col}>
                    <Button className="App-button" onClick={()=>newLocation("/app/administracion/encuestas")}>Nueva Encuesta</Button>
                </Form.Group>
            </Row>
            {secciones.length > 0 ? <>
            <div className="content-header"><h4>Seccion</h4></div>
            <Row className="m-1 mb-2 mt-0">
                <Form.Group as={Col}>
                    <Form.Select onChange={handleSectionChange}>
                        <option>Seleccionar sección</option>
                        {secciones.map((seccion) => <option value={seccion.id}>{seccion.nombre_seccion}</option>)}
                    </Form.Select>
                </Form.Group>
                <Form.Group as={Col}>
                    <Button className="App-button" onClick={()=>newLocation("/app/administracion/secciones")}>Nueva seccion</Button>
                </Form.Group>
            </Row>
            </> 
            : null}
        </Form>
        {sS ? 
            <div className="content-body-alt">
                <div className="content-header"><h4>Preguntas</h4></div>
                <Row>
                    <Col>
                        <Button className="m-3 App-button" onClick={showAdd}> Nueva pregunta </Button>
                    </Col>
                    <Col>
                        {/*<Button className="m-3 App-button" onClick={showMAdd}> Carga masiva </Button>*/}
                    </Col>
                </Row>
                <Table className="App-Table" striped responsive="md" hover size="sm">
                    <thead>
                        <tr>      
                            <th className="table-th">seccion</th>
                            <th className="table-th">segmento</th>                        
                            <th className="table-th">pregunta</th>
                            <th className="table-th">tipo de escala</th>
                            <th className="table-th">peso</th>
                            <th className="table-th">aplica peso?</th>
                            <th className="table-th">aplica calculo?</th>                        
                            <th className="table-th clickable first-col" onClick={() => {
                                 let numbered = preguntas.filter(p => Number(p.orden) > 0)
                                 let unnumbered = preguntas.filter(p => Number(p.orden <= 0))
                                 numbered = numbered.sort((a, b) => Number(a.orden) > Number(b.orden) ? 1 : -1)
                                 let preg = [...numbered,...unnumbered]                
                                 setPreguntas(preg)
                            }}>orden</th>                        
                            <th></th>                      
                        </tr>
                    </thead>
                    <tbody>
                            {preguntas.map((pregunta) => 
                               <tr>                                             
                                    <td>{sS.nombre_seccion}</td>
                                    <td>{getSegmento(pregunta.segmento)}</td>
                                    <td>{pregunta.titulo}</td>
                                    <td>{pregunta.tipo_pregunta}</td>
                                    <td>{pregunta.peso}</td>
                                    <td>{pregunta.aplica_peso > 0 ? "si":"no"}</td>
                                    <td>{pregunta.aplica > 0 ? "si":"no"}</td>
                                    <td>{pregunta.orden}</td>
                                    <td className={pregunta.pausa > 0 ? "clickable-end-paused":"clickable-end"}>
                                        <td className="clickable"><BiEditAlt onClick={() => {handleEditShow(pregunta)}} /></td>
                                        <td className="clickable"><BiTrash onClick={() => {showEliminar(pregunta)}} /></td>
                                    </td>
                                </tr>
                            )}
                    </tbody>
                </Table>
                <div>
                    <InputGroup>
                        <Form.Text className="text-muted">
                        {"Recuerde que al aplicar peso el total debe sumar un 100% entre las preguntas, Peso Actual : "}
                        </Form.Text>
                        <p className={getVal(preguntas.reduce(function (acc, obj) { return acc + obj.peso; }, 0))}>
                            {preguntas.reduce(function (acc, obj) { return acc + obj.peso; }, 0)}
                        </p>
                    </InputGroup>
                    <InputGroup>
                        <Form.Text className="text-muted">
                            {"En la presente tabla todas las preguntas aplican peso ? "}
                        </Form.Text>
                        <p className="m-2 mt-0 mb-0">{getPeso(preguntas)}</p>
                    </InputGroup>
                </div>
        </div>:null}
        <div className="flex-between">
        <Button className="App-button" onClick={() => {newLocation("../segmento-pregunta")}}>Atrás</Button>       
        <Button className="App-button" onClick={() => {newLocation("../zonas")}}>Siguiente</Button> 
        </div>
    </div>
    </>)
} 