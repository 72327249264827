import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";

const YearFilter = ({ selectedYear, setSelectedYear }) => {
    const [years, setYears] = useState([]);

    useEffect(() => {
        const currentYear = new Date().getFullYear();
        const availableYears = Array.from({ length: 5 }, (_, i) => currentYear - i);
        setYears(availableYears);
        setSelectedYear(currentYear); // Año actual por defecto
    }, [setSelectedYear]);

    return (
        <Form.Select /* className="ancho_in" */
            onChange={(e) => setSelectedYear(Number(e.target.value))}
            value={selectedYear}
        >
            {years.map((year) => (
                <option key={year} value={year}>{year}
                </option>
            ))}
        </Form.Select>
    );
};

export default YearFilter;






