import { Row, Col, Form, Modal, Table, Button, Toast, ToastContainer, InputGroup } from "react-bootstrap"
import { useState, useEffect, useContext } from "react";
import ClientContext from "../../../Context/ClientContext";
import { useForm } from "react-hook-form";
import { BiSearch, BiEditAlt, BiTrash } from "react-icons/bi";
import axios from "axios";
import { useNavigate } from "react-router-dom";

export default function SegmentoPregunta() {
    const API_URL = 'https://api.medicionanalytic.com/api'
    const newLocation = useNavigate()
    const {logData} = useContext(ClientContext);
    const [segmentos, setSegmentos] = useState([]);
    const [base, setBase] = useState([]);
    const [toast, setToast] = useState({ show: false, variant: "", message: "" });
    const [show, setShow] = useState(false);
    const [edit, setEdit] = useState(false);
    const [viewElim, setElim] = useState(false);
    const [encuestas, setEncuestas] = useState([])
    const [enc, setEnc] = useState({})
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
      } = useForm({ mode: "onBlur" });
    useEffect(() => {
        axios.get(API_URL+'/segmento', { params: { client_id:logData.id, tipo:"pregunta" } }).then((response) => { setBase(response.data) })
        axios.get(API_URL + '/encuesta/all', {params: {client_id:logData.id}}).then(response => {
            if(typeof response.data === 'object') { let temp = Object.keys(response.data).map((key) => response.data[key]); setEncuestas(temp)    }
            else { setEncuestas(response.data) }
        })
    },[toast])

    const setHeaders = () => {
        let token = window.localStorage.getItem("sessionKey");
        const headers = {
                Authorization: `Bearer ${token}`,
                Accept: "application/json",
            };
        return headers;}

    const handleClose = () => {setShow(false);}
    const handleShow = () => {
        reset({
            aplica: 1,
            descripcion: null,
            estado: 'activo',
            nombre_segmento: null,
            peso: 0
        })
        setShow(true);}
    const editClose = () => setEdit(false);
    const editShow = (segmento) => {
        console.log(segmento)
        reset({
            id: segmento.id,
            aplica: segmento.aplica,
            aplica_calculo: segmento.aplica_calculo,
            client_id: segmento.client_id,
            encuesta_id: segmento.encuesta_id,
            subcategoria: segmento.subcategoria,
            descripcion: segmento.descripcion,
            estado: segmento.estado,
            nombre_segmento: segmento.nombre_segmento,
            peso: segmento.peso,
        })
        setEdit(true);}
    const showEliminar = (s) => {setElim(true);reset({id:s.id})}
    const hideEliminar = () => {setElim(false)}

    function Toaster(variant, message) {setToast({ show: true, variant: variant, message: message });}
    
    const CallModal = (data, e) => {
        e.preventDefault();
        axios
        .post(API_URL + '/segmento', data)
        .then((response) => {
        handleClose()
        Toaster("success", response.data.message); })
    }
    const EditModal = (data,e) => {
        e.preventDefault();
        let headers = setHeaders();
        let formData = new FormData();
        formData.append("_method", "PATCH");
        formData.append("client_id", data.client_id);
        formData.append("nombre_segmento", data.nombre_segmento);
        formData.append("peso", data.peso);
        formData.append("encuesta_id", data.encuesta_id);
        formData.append("estado", data.estado);
        formData.append("subcategoria", data.subcategoria);        
        if(data.descripcion === null) {
            formData.append("descripcion", " ");
        }
        else {formData.append("descripcion", data.descripcion);}
        if(data.aplica) {formData.append("aplica", 1);}
        else {formData.append("aplica", 0)}
        if(data.aplica_calculo) {formData.append("aplica_calculo", 1);}
        else {formData.append("aplica_calculo", 0)}
        formData.append("tipo", data.tipo);
        axios.post(API_URL + `/segmento/${data.id}`, formData, {headers}).then(res => {
            editClose();
            Toaster("success", res.data.message);
        })
    }
    const Remove = (i,e) => {
        e.preventDefault()
        let headers = setHeaders()
        axios.delete(API_URL + `/segmento/${i.id}`, {headers}).then(res => {
            Toaster("success", res.data.message)
            hideEliminar()
        })
    }
    const handleChange = (e) => {
        e.preventDefault();
        if(e.target !== undefined) {
            let tempenc = encuestas.find(en => {return Number(en.id) === Number(e.target.value)})
            setEnc(tempenc)
            setSegmentos(base.filter(p => {return Number(p.encuesta_id) === Number(e.target.value)}))
        }
    }
useEffect(() => {
    if(enc) {
        setSegmentos(base.filter(p => {return Number(p.encuesta_id) === Number(enc.id)}))
    }
},[toast])
return(<>
    <ToastContainer style={{position:"fixed",top:"80vh", right:"0vw"}} className="p-3">
        <Toast
          bg={toast.variant}
          onClose={() => setToast({ show: false, variant: "", message: "" })}
          show={toast.show}
          delay={2000}
          autohide>
          <Toast.Header>
            <strong className="me-auto">Medicion</strong>
            <small>just now</small>
          </Toast.Header>
          <Toast.Body className="text-white">{toast.message}</Toast.Body>
        </Toast>
    </ToastContainer>
    <Modal show={viewElim} onHide={hideEliminar}>
        <Modal.Header closeButton>
          <Modal.Title>Eliminar Segmento</Modal.Title>
        </Modal.Header>
            <Form onSubmit={handleSubmit(Remove)}>
                <Modal.Body>
                    <h4>Esta seguro ?</h4>
                    <h5>esta acción no puede deshacerse</h5>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="App-button-alt" onClick={hideEliminar}>Cerrar</Button>
                    <Button type="submit" className="App-button">Eliminar</Button>
                </Modal.Footer>
            </Form>
        </Modal>
    <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Nuevo Segmento</Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleSubmit(CallModal)}>
            <Modal.Body>
                <Form.Control type="hidden" value={logData.id} {...register("client_id")} />
                <Form.Control type="hidden" value={"pregunta"} {...register("tipo")} />
                <Row>
                    <Form.Group as={Col} className="mb-2">
                        <Form.Label>Nombre del segmento</Form.Label>
                        <Form.Control placeholder="Nombre" {...register("nombre_segmento")}/>
                    </Form.Group>
                    <Form.Group as={Col} className="mb-2">
                        <Form.Label>Estado</Form.Label>                
                        <Form.Select {...register("estado")}>
                            <option value="activo">Activo</option>
                            <option value="inactivo">Inactivo</option>
                        </Form.Select>
                    </Form.Group>
                </Row>
               <Form.Group className="mb-2">
                    <Form.Label>Descripcion</Form.Label>
                    <Form.Control size="sm" as="textarea" rows={3} {...register("descripcion")} />
                    <Form.Text className="text-muted"></Form.Text>
                </Form.Group> 
                <Row>
                    <Form.Group as={Col} className="mb-2">
                        <Form.Label> Aplica calculo?</Form.Label>
                        <Form.Check type="checkbox" defaultChecked {...register("aplica_calculo")} />
                    </Form.Group>
                    <Form.Group as={Col} className="mb-2">                        
                        <Form.Label>Peso (%)</Form.Label>
                        <Form.Control size="sm" {...register("peso")}/>
                        <Form.Text className="text-muted">Peso sobrante (100-peso)</Form.Text>
                    </Form.Group>
                    <Form.Group as={Col} className="mb-2">
                        <Form.Label> Aplica peso?</Form.Label>
                        <Form.Check type="checkbox" {...register("aplica")}/>
                        <Form.Text className="text-muted"></Form.Text>                        
                    </Form.Group>
                </Row>
                <Row>
                    <Form.Group as={Col} className="mb-2">
                        <Form.Label>Encuesta</Form.Label>                
                        <Form.Select defaultValue={enc && enc.id !== undefined ? enc.id : null} {...register("encuesta_id")}>                                                        
                            {encuestas.map(e => {return <option value={e.id}>{e.nombre_encuesta}</option>})}
                        </Form.Select>
                    </Form.Group>
                    <Form.Group as={Col} className="mb-2">
                        <Form.Label>Subcategoria</Form.Label>                
                        <Form.Control {...register("subcategoria")} />
                    </Form.Group>
                </Row>
            </Modal.Body>
            <Modal.Footer>
            <Button className="App-button-alt" onClick={handleClose}>
                Cerrar
            </Button>
            <Button type="submit" className="App-button">
                Crear
            </Button>
            </Modal.Footer>
        </Form>
      </Modal>
    <Modal show={edit} onHide={editClose}>
        <Modal.Header closeButton>
          <Modal.Title>Editar Segmento</Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleSubmit(EditModal)}>
            <Modal.Body>
                <Form.Control type="hidden" value={logData.id} {...register("client_id")} />
                <Form.Control type="hidden" value={"pregunta"} {...register("tipo")} />
                <Form.Control type="hidden" {...register("id")} />
                <Row>
                    <Form.Group as={Col} className="mb-2">
                        <Form.Label>Nombre del segmento</Form.Label>
                        <Form.Control size="sm" placeholder="Nombre" {...register("nombre_segmento")}/>
                        <Form.Text className="text-muted"></Form.Text>
                    </Form.Group>
                    <Form.Group as={Col} className="mb-2">
                        <Form.Label>Estado</Form.Label>                
                        <Form.Select {...register("estado")}>
                            <option value="activo">Activo</option>
                            <option value="inactivo">Inactivo</option>
                        </Form.Select>
                    </Form.Group>
                </Row>
               <Form.Group className="mb-2">
                    <Form.Label>Descripción</Form.Label>
                    <Form.Control size="sm" as="textarea" rows={3} {...register("descripcion")} />
                    <Form.Text className="text-muted"></Form.Text>
                </Form.Group> 
                <Row>
                    <Form.Group as={Col} className="mb-2">
                        <Form.Label> Aplica calculo?</Form.Label>
                        <Form.Check type="checkbox" defaultChecked {...register("aplica_calculo")} />
                    </Form.Group>
                    <Form.Group as={Col} className="mb-2">
                        <Form.Label>Peso (%)</Form.Label>
                        <Form.Control size="sm" {...register("peso")}/>
                        <Form.Text className="text-muted">Peso sobrante (100-peso)</Form.Text>
                    </Form.Group>
                    <Form.Group as={Col} className="mb-2">                    
                        <Form.Label> Aplica peso?</Form.Label>
                        <Form.Check type="checkbox" {...register("aplica")}/>
                        <Form.Text className="text-muted"></Form.Text>
                    </Form.Group>                    
                </Row>
                <Row>
                    <Form.Group as={Col} className="mb-2">
                        <Form.Label>Encuesta</Form.Label>                
                        <Form.Select {...register("encuesta_id")}>   
                            {encuestas.map(e => {return <option value={e.id}>{e.nombre_encuesta}</option>})}                                                     
                        </Form.Select>
                    </Form.Group>
                    <Form.Group as={Col} className="mb-2">
                        <Form.Label>Subcategoria</Form.Label>                
                        <Form.Control {...register("subcategoria")} />                        
                    </Form.Group>
                </Row>
            </Modal.Body>
            <Modal.Footer>
            <Button className="App-button-alt" onClick={editClose}>
                Cerrar
            </Button>
            <Button type="submit" className="App-button">
                Actualizar
            </Button>
            </Modal.Footer>
        </Form>
      </Modal>
        <div className="content-container">
        <div className="content-header mb-3"><h4>Segmento preguntas</h4></div>
                <Form.Group as={Col}>
                    <Form.Select onChange={handleChange}>
                        <option>Seleccionar encuesta...</option>
                        {encuestas.map((encuesta) => <option value={encuesta.id}>{encuesta.nombre_encuesta}</option>)}
                    </Form.Select>
                </Form.Group>
            <div className="content-body">
                <Row className="m-3 ">
                    <Col><Button className="App-button" disabled={enc && enc.id !== undefined ? false : true} onClick={handleShow}> Nuevo segmento </Button></Col>
                    <Col>
                    {/*<Form onSubmit={handleSubmit(SetFilters)}>
                        <InputGroup>
                            <Form.Control placeholder="Buscar segmento..." {...register("segmento")} />
                            <Button className="App-button" type="submit"><BiSearch /></Button>
                        </InputGroup>
                    </Form>*/}
                    </Col>
                </Row>
                <Table className="App-Table" striped responsive="md" hover size="sm">
                    <thead>
                        <tr>                        
                            <th className="table-th">Segmentos de pregunta</th>
                            <th className="table-th">Descripcion</th>{/* extended */}
                            <th className="table-th">Peso (%)</th>
                            <th className="table-th">Aplica Peso ?</th>
                            <th className="table-th">Aplica Calculo ?</th>
                            <th className="table-th">Estado</th>
                            <th></th>                        
                        </tr>
                    </thead>
                    <tbody>
                            {segmentos.map((segmento) => 
                               <tr>                                
                                    <td>{segmento.nombre_segmento}</td>
                                    <td>{segmento.descripcion}</td>
                                    <td>{segmento.peso}</td>
                                    <td>{segmento.aplica > 0 ? "si": "no"}</td>
                                    <td>{segmento.aplica_calculo > 0 ? "si" : "no"}</td>
                                    <td>{segmento.estado}</td>
                                    <td className="clickable-end">
                                        <BiEditAlt onClick={() => editShow(segmento)}/>
                                        <BiTrash onClick={() => showEliminar(segmento)}/>    
                                    </td>
                                </tr>
                            )}
                    </tbody>
                </Table>
            </div>
            <div className="flex-between">
                <Button className="App-button" onClick={() => {newLocation("../secciones")}}>Atrás</Button>
                <Button className="App-button" onClick={() => {newLocation("../preguntas")}}>Siguiente</Button>
            </div>
        </div>
    </>)
}